import React from 'react'

export default function CultureHero() {
  return (
    <div>
      <div className="relative h-[55vh] bg-cover bg-center" style={{ backgroundImage: 'url(https://images.pexels.com/photos/6254423/pexels-photo-6254423.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)' }}>
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white">
          <h1 className="text-5xl md:text-7xl font-bold mb-4 text-center text-primary headerText">Cultural Spiti</h1>
          <p className="text-sm md:text-xl mb-8 text-center max-w-2xl">Embark on unforgettable spiritual experience in the heart of the Himalayas</p>

        </div>
      </div>
    </div>
  )
}
