import React from 'react'
import TourSlider from '../components/home/TourSlider'
import TrekSlider from '../components/home/TrekSlider'
import LeopardCTA from '../components/home/LeopardCTA'
import WhyUs from '../components/home/WhyUs'
import ExploreSpiti from '../components/home/ExploreSpiti'
import CultureSlider from '../components/home/CultureSlider'
import SpitiTourComponent from '../components/tours/SpitiTourComponent'

export default function Home() {
    return (
        <>
            {/* <Hero /> */}
            <ExploreSpiti />
            <TourSlider />
            <TrekSlider />
            <CultureSlider />
            <LeopardCTA />
            <WhyUs />
            <SpitiTourComponent />
        </>
    )
}
