import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaRegHeart } from 'react-icons/fa'; // Added for the heart icon
import 'swiper/css';
import 'swiper/css/pagination';

import BG from "../../assets/bg/9.png"


export default function ExploreSpiti() {
    const [heros, setHeros] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch heros from API
    useEffect(() => {
        const fetchHeros = async () => {
            try {
                const response = await fetch('https://explorespiti-backend.vercel.app/api/tours/');
                if (!response.ok) {
                    throw new Error('Failed to fetch heros');
                }
                const data = await response.json();
                setHeros(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchHeros();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    // Helper function to generate Google Drive thumbnail URL
    const generateImageUrl = (fileId) => {
        return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
    };

    return (
        <div className="relative w-full">
            {/* Background Image */}
            <img src={BG} alt="trekker" className="absolute top-0 right-0 -z-[10] sm:opacity-30 opacity-20 w-[100%] h-auto object-contain -translate-x-1/5 -translate-y-1/4" />

            {/* Content Container */}
            <div className="relative z-20 h-full flex flex-col justify-between p-12 pt-24 sm:p-24">
                {/* Header Content */}
                <div className="max-w-2xl mb-8 md:mb-12">
                    <h1 className="text-4xl font-extrabold sm:text-5xl md:text-7xl heroText text-black justify-center">
                        Explore
                        <strong className="block font-extrabold text-primary"> Spiti </strong>
                    </h1>

                    <p className="mt-4 max-w-xl text-sm sm:text-base md:text-lg text-gray-600">
                        Your One-stop portal to immersive experience into the high altitude Spiti Valley for the best of Himalayan Culture, Treks, Wildlife and Adventure Road trips with the local experts.
                    </p>
                </div>

                {/* Tours Slider */}
                <div className="w-full mt-2">
                    <Swiper
                        grabCursor={true}
                        spaceBetween={5}
                        slidesPerView={2}
                        centeredSlides={false}
                        className="swiper-container w-full flex justify-center items-center mx-auto"
                        breakpoints={{
                            320: { slidesPerView: 2 },
                            480: { slidesPerView: 2 },
                            768: { slidesPerView: 3.2 },
                            1024: { slidesPerView: 4 },
                        }}
                    >
                        {heros.map((hero, index) => (
                            <SwiperSlide key={index}>
                                <div className="relative aspect-square rounded-2xl overflow-hidden group cursor-pointer bg-gray-900">
                                    <img
                                        src={generateImageUrl(hero.images[0])}
                                        alt={hero.title}
                                        className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                                    />
                                    {/* Darker gradient overlay */}
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-black/20" />

                                    {/* Heart Icon */}
                                    {/* <button className="absolute top-3 right-3 p-2 rounded-full bg-black/20 hover:bg-black/40 transition-colors">
                                        <FaRegHeart className="text-white w-4 h-4" />
                                    </button> */}

                                    {/* Content */}
                                    <div className="absolute bottom-0 left-0 p-4 w-full">
                                        <div className="space-y-1">
                                            <h3 className="text-lg font-medium text-white group-hover:text-gray-200 transition-colors">
                                                {hero.title}
                                            </h3>
                                            <p className="text-sm text-gray-300">
                                                {hero.costOnRequest ? "Cost on Request" : `${hero.price.discounted || hero.price.regular}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}