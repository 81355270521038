import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import TourCard from '../content/TourCard';

const TourSlider = () => {
    const [tours, setTours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch tours from API
    useEffect(() => {
        const fetchTours = async () => {
            try {
                const response = await fetch('https://explorespiti-backend.vercel.app/api/tours/');
                if (!response.ok) {
                    throw new Error('Failed to fetch tours');
                }
                const data = await response.json();
                setTours(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTours();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

     // Helper function to generate Google Drive thumbnail URL
     const generateImageUrl = (fileId) => {
        return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
    };


    return (
        <div className="w-full max-w-7xl mx-auto p-4 flex flex-col items-center justify-center">
            {/* Header */}
            <h1 className="sm:text-2xl text-primary text-xl font-bold text-center mt-4 mb-1 headerText z-1">Explore Spiti<span className='someText'>'</span>s Popular Tours</h1>

            {/* Swiper */}
            <Swiper
                grabCursor={true}
                spaceBetween={5}
                slidesPerView={2}
                centeredSlides={false}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="swiper-container w-full flex justify-center items-center mx-auto"
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 5,
                    },
                    425: {
                        slidesPerView: 2.5,
                        spaceBetween: 5,
                    },
                    540: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 5,
                        spaceBetween: 25,
                    },
                }}
            >
                {tours.map(({ _id, images, title, costOnRequest, groupSize, price }) => (
                    <SwiperSlide key={_id} className="flex items-center justify-center sm:pt-4 sm:pb-8 py-8">
                        <TourCard
                            id={_id}
                            imgSrc={generateImageUrl(images[0])} // Assuming the first image is the main one
                            altText={title}
                            title={title}
                            seats={groupSize}
                            price={costOnRequest ? "Cost on Request" : `${price.discounted || price.regular}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default TourSlider;
