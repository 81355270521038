import React from 'react'
import CultureHero from '../components/cultures/CultureHero'
import PopularDestinations from '../components/home/PopularDestinations'
import CultureSlider from '../components/home/CultureSlider'

export default function Cultures() {
  return (
    <div>
        <CultureHero />
        <CultureSlider />
        <PopularDestinations />
    </div>
  )
}
