import React from 'react'
import TourHero from '../components/tours/TourHero'
import TourSlider from '../components/home/TourSlider'
import TrekSlider from '../components/home/TrekSlider'
import FAQSection from '../components/FAQSection'
import SpitiTourComponent from '../components/tours/SpitiTourComponent'

export default function Tours() {
  return (
    <>
      <TourHero />
      <TourSlider />
      <SpitiTourComponent />
      <TrekSlider />
      <FAQSection />
    </>
  )
}
