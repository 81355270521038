import React from 'react';
import { FaRupeeSign, FaUser, FaUsers } from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const CultureCard = ({ id, imgSrc, altText, title, seats, price }) => {
    // Helper function to generate Google Drive thumbnail URL
    const generateImageUrl = (fileId) => {
        return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
    };


    return (
        <Link to={`/cultures/${id}`} className="block">
            <div className="sm:w-56 sm:h-52 w-36 h-44 rounded-2xl bg-slate-100 p-2 overflow-hidden shadow-lg transition-transform duration-500 ease-out hover:scale-105 flex flex-col">
                <div className="sm:h-32 h-24 rounded-lg bg-gradient-to-br from-[#04a0bb] to-[#50f6ff] relative flex flex-col">
                    <img
                        src={imgSrc}
                        alt={altText}
                        className="w-full h-full object-cover rounded-lg"
                    />
                </div>
                <div className="mt-1 px-1 flex-0">
                    <span className="block text-center text-primary sm:text-sm text-xs font-semibold">
                        {title}
                    </span>
                </div>
                <div className="flex justify-between sm:mt-4 mt-2">
                    <div className="flex items-center font-semibold">
                        <FaUsers className="mr-1 text-primary text-sm " />
                        <span className='text-gray-600 text-xs'>{seats} people</span>
                    </div>
                    <div className="flex items-center text-primary font-semibold">
                        <FaRupeeSign className="mr-1 text-sm" />
                        <span className="text-gray-600 text-xs ">{price}</span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default CultureCard;