import React, { useState } from 'react';
import { faqData } from '../constants';

import BG from "../assets/bg/5.png"

const FAQSection = () => {

    const [openIndex, setOpenIndex] = useState(null);

    // Toggle FAQ to open and close answers
    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="relative sm:py-10 py-2 px-2 max-w-5xl mx-auto flex flex-col md:flex-row gap-12">
            {/* <img
                src={BG}
                alt="trekker"
                className="absolute -z-[20] sm:opacity-20 opacity-20 w-[50%] h-auto object-contain -translate-x-1/5 -translate-y-1/4"
            /> */}
            <div className="flex flex-col sm:text-left text-center basis-1/2">
                <p className="inline-block font-light text-black mb-2">
                    We've got your back!
                </p>
                <p className="sm:text-2xl text-primary text-xl font-bold text-base-content headerText">
                    Frequently Asked Questions
                </p>
            </div>
            <ul className="basis-1/2 px-2">
                {faqData.map((faq, index) => (
                    <li key={index}>
                        <button
                            className="relative flex gap-2 items-center w-full sm:py-5 py-3 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
                            aria-expanded={openIndex === index ? 'true' : 'false'}
                            onClick={() => toggleFAQ(index)}
                        >
                            <span className="flex-1 font-semibold sm:text-base text-sm text-orange-400">{faq.question}</span>
                            <svg
                                className={`flex-shrink-0 w-4 h-4 ml-auto fill-current transition-transform text-secondary ${openIndex === index ? 'rotate-90' : ''
                                    }`}
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect y="7" width="16" height="2" rx="1"></rect>
                                <rect
                                    y="7"
                                    width="16"
                                    height="2"
                                    rx="1"
                                    className="transform origin-center rotate-90"
                                ></rect>
                            </svg>
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${openIndex === index ? 'max-h-screen' : 'max-h-0'
                                }`}
                            style={{
                                transition: 'max-height 0.3s ease-in-out',
                            }}
                        >
                            <div className="pb-5 leading-relaxed">
                                <div className="space-y-2 leading-relaxed font-thin text-sm text-gray-500">{faq.answer}</div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FAQSection;
