import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaCalendar, FaClock, FaUsers, FaMapMarkerAlt, FaStar } from 'react-icons/fa';
import FAQSection from '../FAQSection';
import TourImageGallery from './TourImageGallery';

const TourDetails = () => {
    const [tour, setTour] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchTour = async () => {
            try {
                const response = await fetch(`https://explorespiti-backend.vercel.app/api/tours/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch tour details');
                }
                const data = await response.json();
                setTour(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTour();
    }, [id]);

    if (loading) return <div className="text-center py-10 text-white">Loading...</div>;
    if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
    if (!tour) return <div className="text-center py-10 text-white">Tour not found</div>;

    // Helper function to generate Google Drive thumbnail URL
    const generateImageUrl = (fileId) => {
        return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
    };

    return (
        <div className="min-h-screen text-white">
            {/* Hero Section */}

            <div
                className="relative h-[70vh] bg-cover bg-center"
                style={{ backgroundImage: `url(${generateImageUrl(tour.images[0])})` }}
            >                <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white">
                    <h1 className="text-xl md:text-7xl font-bold mb-4 text-center text-primary titleText">{tour.title}</h1>
                    <p className="text-sm md:text-lg mb-8 text-center max-w-2xl">{tour.description}</p>

                </div>
            </div>

            {/* Tour Details */}
            <div className="container mx-auto px-4 py-16">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
                        <div className=" rounded-lg p-6 mb-8">
                            <h2 className="text-2xl font-bold mb-4 text-orange-400 headerText">Tour Highlights</h2>
                            <ul className="list-disc list-inside text-black">
                                {tour.highlights.map((highlight, index) => (
                                    <li key={index} className="mb-2">{highlight}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="rounded-lg p-6">
                            <h2 className="text-2xl text-orange-400 font-bold mb-4 headerText">Itinerary</h2>
                            {tour.itinerary.map((day, index) => (
                                <div key={index} className="mb-6 last:mb-0">
                                    <h3 className="text-xl text-orange-500 font-semibold mb-2">Day {day.day}: {day.title}</h3>
                                    <p className="mb-2 text-black">{day.description}</p>
                                    <ul className="list-disc list-inside text-gray-500">
                                        {day.activities.map((activity, actIndex) => (
                                            <li key={actIndex}>{activity}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 px-4">
                        <div className="bg-orange-200 rounded-lg p-6 sticky top-4">
                            <h2 className="text-xl font-bold mb-4 headerText text-orange-400">Tour Details</h2>
                            <div className="space-y-4">
                                <div className="flex items-center">
                                    <FaCalendar className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Start Date: {new Date(tour.startDate).toLocaleDateString()}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaClock className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Duration: {tour.duration}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaUsers className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Group Size: {tour.groupSize}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaMapMarkerAlt className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Difficulty: {tour.difficultyLevel}</span>
                                </div>
                                <div className="text-2xl font-bold text-orange-500">
                                    {tour.costOnRequest ? (
                                        <span>Cost on Request</span>
                                    ) : (
                                        <>
                                            Price: ₹{tour.price.discounted}
                                            {tour.price.regular !== tour.price.discounted && (
                                                <span className="text-lg line-through text-yellow-500 ml-2">₹{tour.price.regular}</span>
                                            )}
                                        </>
                                    )}
                                </div>
                                <button className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300">
                                    Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TourImageGallery id={id} />


            {/* FAQ Section */}
            <FAQSection />


        </div>
    );
};

export default TourDetails;
