import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaCalendar, FaClock, FaUsers, FaMapMarkerAlt, FaStar, FaMountain, FaRupeeSign } from 'react-icons/fa';
import { MdLocationOn, MdCalendarToday, MdTerrain, MdAttachMoney } from 'react-icons/md';
import FAQSection from '../FAQSection';

const TrekDetails = () => {
    const [trek, setTrek] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchTrek = async () => {
            try {
                const response = await fetch(`https://explorespiti-backend.vercel.app/api/treks/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch trek details');
                }
                const data = await response.json();
                setTrek(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTrek();
    }, [id]);

    if (loading) return <div className="text-center py-10 text-white">Loading...</div>;
    if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
    if (!trek) return <div className="text-center py-10 text-white">Trek not found</div>;

    // Helper function to generate Google Drive thumbnail URL
    const generateImageUrl = (fileId) => {
        return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
    };


    return (
        <div className="min-h-screen text-white">
            {/* Hero Section */}

            <div
                className="relative h-[70vh] bg-cover bg-center"
                style={{ backgroundImage: `url(${generateImageUrl(trek.images[0])})` }}
            >                <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white">
                    <h1 className="text-xl md:text-7xl font-bold mb-4 text-center text-primary titleText">{trek.title}</h1>
                    <p className="text-sm md:text-lg mb-8 text-center max-w-2xl">{trek.description}</p>

                </div>
            </div>

            {/* Quick Info Section */}
            <div className="max-w-7xl mx-auto px-4 py-8 -mt-20 relative z-10">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    <InfoCard icon={<MdCalendarToday />} title="Duration" value={trek.duration} />
                    <InfoCard icon={<FaMountain />} title="Max Elevation" value={trek.maxElevation || 'Not specified'} />
                    <InfoCard icon={<MdTerrain />} title="Difficulty" value={trek.difficultyLevel} />
                    <InfoCard icon={<FaRupeeSign />} title="Price" value={trek.costOnRequest ? (
                        <span>Cost on Request</span>
                    ) : (
                        <>
                            Price: ₹{trek.price.discounted}
                            {trek.price.regular !== trek.price.discounted && (
                                <span className="text-lg line-through text-yellow-500 ml-2">₹{trek.price.regular}</span>
                            )}
                        </>
                    )} />
                </div>
            </div>

            {/* Trek Details */}
            <div className="container mx-auto px-4 py-16">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-2/3 px-4 mb-8 lg:mb-0">
                        <div className=" rounded-lg p-6 mb-8">
                            <h2 className="text-2xl font-bold mb-4 text-orange-400 headerText">Trek Highlights</h2>
                            <ul className="list-disc list-inside text-black">
                                {trek.highlights.map((highlight, index) => (
                                    <li key={index} className="mb-2">{highlight}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="rounded-lg p-6">
                            <h2 className="text-2xl text-orange-400 font-bold mb-4 headerText">Itinerary</h2>
                            {trek.itinerary.map((day, index) => (
                                <div key={index} className="mb-6 last:mb-0">
                                    <h3 className="text-xl text-orange-500 font-semibold mb-2">Day {day.day}: {day.title}</h3>
                                    <p className="mb-2 text-black">{day.description}</p>
                                    <ul className="list-disc list-inside text-gray-500">
                                        {day.activities.map((activity, actIndex) => (
                                            <li key={actIndex}>{activity}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 px-4">
                        <div className="bg-orange-200 rounded-lg p-6 sticky top-4">
                            <h2 className="text-xl font-bold mb-4 headerText text-orange-400">Trek Details</h2>
                            <div className="space-y-4">
                                <div className="flex items-center">
                                    <FaCalendar className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Start Date: {new Date(trek.startDate).toLocaleDateString()}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaClock className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Duration: {trek.duration}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaUsers className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Group Size: {trek.groupSize}</span>
                                </div>
                                <div className="flex items-center">
                                    <FaMapMarkerAlt className="mr-2 text-orange-600" />
                                    <span className='text-gray-600'>Difficulty: {trek.difficultyLevel}</span>
                                </div>
                                <div className="text-2xl font-bold text-orange-500">
                                    {trek.costOnRequest ? (
                                        <span>Cost on Request</span>
                                    ) : (
                                        <>
                                            Price: ₹{trek.price.discounted}
                                            {trek.price.regular !== trek.price.discounted && (
                                                <span className="text-lg line-through text-yellow-500 ml-2">₹{trek.price.regular}</span>
                                            )}
                                        </>
                                    )}
                                </div>
                                <button className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300">
                                    Book Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* FAQ Section */}
            <FAQSection />


        </div>
    );
};

const InfoCard = ({ icon, title, value }) => (
    <div className="bg-yellow-100 backdrop-blur-sm p-6 rounded-lg">
        <div className="flex items-center gap-3">
            <div className="text-2xl text-secondary">{icon}</div>
            <div>
                <h3 className="font-semibold text-primary">{title}</h3>
                <p className='text-black'>{value}</p>
            </div>
        </div>
    </div>
);

export default TrekDetails;
