import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Menu } from '@headlessui/react';
import { FaHome, FaHiking, FaBook, FaMapMarkedAlt, FaPhoneAlt } from 'react-icons/fa'; // Import React Icons
import { MdTempleBuddhist } from 'react-icons/md';

const Header = () => {
    const [color, setColor] = useState(false);
    // const [showBottomNav, setShowBottomNav] = useState(false);

    const navItems = [
        { name: 'Home', path: '/', icon: <FaHome /> },
        { name: 'Tours', path: '/tours', icon: <FaMapMarkedAlt /> },
        { name: 'Treks', path: '/treks', icon: <FaHiking /> },
        { name: 'Culture', path: '/cultures', icon: <MdTempleBuddhist /> },
        { name: 'Contact Us', path: '/contact-us', icon: <FaPhoneAlt /> },
    ];

    const changeColor = () => {
        if (window.scrollY >= 10) {
            setColor(true);
            // setShowBottomNav(true);
        } else {
            setColor(false);
            // setShowBottomNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeColor);
        return () => window.removeEventListener('scroll', changeColor);
    }, []);

    return (
        <div className='z-[100] fixed'>
            {/* Top Header (Hidden on Mobile Screens) */}
            <header className={`fixed top-0 left-0 right-0 z-50 ${color ? 'header' : 'header-bg'} hidden md:flex`}>
                <div className="container mx-auto h-16 items-center justify-between px-4 sm:px-6 lg:px-8 md:flex hidden">
                    <Link to="/" className="flex items-center text-lg font-bold text-primary">
                        Explore Spiti
                    </Link>
                    <nav className="flex items-center space-x-4">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                to={item.path}
                                className="text-sm font-medium text-orange-500 hover:text-secondary transition-colors"
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>
                    <button className="bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md transition-colors">
                        Book Now
                    </button>
                    <Menu as="div" className="relative md:hidden">
                        <Menu.Button className="flex items-center p-2 rounded-md text-primary hover:bg-[#f1f1f1]">
                            <FontAwesomeIcon icon={faBars} />
                            <span className="sr-only">Toggle navigation menu</span>
                        </Menu.Button>
                        <Menu.Items className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
                            <div className="p-2 space-y-2">
                                {navItems.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <Link
                                                to={item.path}
                                                className={`block px-4 py-2 text-sm font-medium text-primary hover:text-secondary transition-colors ${active ? 'bg-gray-100' : ''}`}
                                            >
                                                {item.name}
                                            </Link>
                                        )}
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Menu>
                </div>
            </header>

            {/* Bottom Navbar for Mobile Screens */}
            {/* {showBottomNav && ( */}
            <nav className="fixed bottom-0 left-0 right-0 bg-black/50 bg-opacity-50 backdrop-blur-xl border-t border-gray-200 shadow-lg md:hidden z-40">
                <div className="container mx-auto flex justify-around p-2">
                    {navItems.map((item) => (
                        <Link
                            key={item.name}
                            to={item.path}
                            className="flex flex-col items-center text-sm text-primary hover:text-secondary transition-colors py-1"
                        >
                            {item.icon}
                            <span className="mt-1 text-[10px]  ">{item.name}</span>
                        </Link>
                    ))}
                </div>
            </nav>
            {/* )} */}
        </div>
    );
};

export default Header;
