import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { FaRegHeart } from 'react-icons/fa';
import { useParams } from 'react-router-dom'; // For getting the ID from the URL

// Helper function to generate Google Drive thumbnail URL
const generateImageUrl = (fileId) => {
    return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
};

export default function TourImageGallery() {
    const { id } = useParams(); // Extract the tour ID from the URL
    const [tour, setTour] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch tour data
    useEffect(() => {
        const fetchTour = async () => {
            try {
                const response = await fetch(`https://explorespiti-backend.vercel.app/api/tours/${id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch tour data');
                }
                const data = await response.json();
                setTour(data);
            } catch (error) {
                console.error('Error fetching tour:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchTour();
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="p-6 bg-gray-100">
            <h2 className="text-3xl font-bold text-center mb-8">Tour Gallery</h2>
            {/* Responsive LightGallery Image Grid */}
            {tour.images && tour.images.length > 0 ? (
                <LightGallery
                    speed={500}
                    plugins={['zoom', 'thumbnail']}
                    elementClassNames="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4"
                >
                    {tour.images.map((image, index) => (
                        <a
                            key={index}
                            href={generateImageUrl(image)} // Full-size image
                            className="relative group"
                        >
                            <img
                                src={generateImageUrl(image)}
                                alt={`Tour ${index + 1}`}
                                className="w-full h-48 object-cover rounded-lg shadow-md transition-transform duration-300 group-hover:scale-105"
                            />
                            {/* Heart Icon */}
                            <button className="absolute top-2 right-2 p-2 rounded-full bg-black/20 hover:bg-black/40 transition-colors">
                                <FaRegHeart className="text-white w-5 h-5" />
                            </button>
                        </a>
                    ))}
                </LightGallery>
            ) : (
                <div className="text-center">
                    <p>No images available for this tour.</p>
                </div>
            )}
        </div>
    );
}
